import { WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_SEND } from './types';

const url = "wss://13.37.63.238"
//const url = "ws://localhost:8000"

// Action creator to connect to a WebSocket
export const connectWebSocket = () => ({
  type: WEBSOCKET_CONNECT,
  payload: { url },
});

// Action creator to disconnect from a WebSocket
export const disconnectWebSocket = () => ({
  type: WEBSOCKET_DISCONNECT,
});

// Action creator to send a message over a WebSocket
export const sendWebSocketMessage = message => ({
  type: WEBSOCKET_SEND,
  payload: { message },
});