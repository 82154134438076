import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, TableCell, TableRow, TableHead, Table, TableContainer, TableBody, Paper, Dialog, IconButton, ToggleButtonGroup, ToggleButton, FormControlLabel, FormLabel, RadioGroup, Radio, CardContent, Card } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getTournament } from '../app/store/actions/userActions';
import { connectWebSocket } from "../app/store/actions/websocketActions";
import { centered_flex_box, left_flex_box, right_flex_box } from '../app/components/Styles';
import { AccountTree, EmojiEvents, TableChart } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { MatchCard } from '../app/components';

export const Tournament = ({ getTournament, connectWebSocket, tournament, matches, groupedTeams, isLoading }) => {

    const navigate = useNavigate();
    const tournamentId = useParams().tournamentId

    useEffect(() => {
        getTournament({ tournamentId })
        connectWebSocket()
    }, [])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box, width: "100vw", height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Box sx={{ minHeight: "90vh" }}>
            <Box sx={{ ...centered_flex_box, width: "100vw", color: "var(--primaryColor)", background: "var(--secColor)", padding: 3, marginBottom: 2 }}>
                {tournament?.type === "Knockout" ? (<EmojiEvents sx={{ marginInlineEnd: 2 }} />) : (tournament?.type === "League" ? (<TableChart sx={{ marginInlineEnd: 2 }} />) : (<EmojiEvents sx={{ marginInlineEnd: 2 }} />))}<Typography fontWeight="bold" fontSize={25}>{tournament?.name}</Typography>
            </Box>
            {tournament?.type === "League" && <Box sx={{ padding: 5 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>PL</TableCell>
                                <TableCell>W</TableCell>
                                <TableCell>D</TableCell>
                                <TableCell>L</TableCell>
                                <TableCell>+/-</TableCell>
                                <TableCell>GD</TableCell>
                                <TableCell>PTS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupedTeams["A"]?.map((team, idx) => (
                                <TableRow key={team._id}>
                                    <TableCell>{team.name}</TableCell>
                                    <TableCell>{team.played}</TableCell>
                                    <TableCell>{team.won}</TableCell>
                                    <TableCell>{team.draw}</TableCell>
                                    <TableCell>{team.lost}</TableCell>
                                    <TableCell>{team.gf}-{team.ga}</TableCell>
                                    <TableCell>{parseInt(team.gf) - parseInt(team.ga)}</TableCell>
                                    <TableCell>{team.points}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {tournament?.type === "Group-Knockout" && <>
                <Typography fontWeight="bold" textAlign="center" fontSize={20}>Group Stage</Typography>
                <Grid container sx={{ ...centered_flex_box, padding: 5 }} spacing={2}>
                    {groupedTeams && Object.keys(groupedTeams).map((group, i) => {
                        return (
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Typography fontWeight="bold">Group {group}</Typography>
                                <TableContainer key={i}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>PL</TableCell>
                                                <TableCell>W</TableCell>
                                                <TableCell>D</TableCell>
                                                <TableCell>L</TableCell>
                                                <TableCell>+/-</TableCell>
                                                <TableCell>GD</TableCell>
                                                <TableCell>PTS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {groupedTeams[group]?.map((team, idx) => (
                                                <TableRow key={team._id}>
                                                    <TableCell>{team.name}</TableCell>
                                                    <TableCell>{team.played}</TableCell>
                                                    <TableCell>{team.won}</TableCell>
                                                    <TableCell>{team.draw}</TableCell>
                                                    <TableCell>{team.lost}</TableCell>
                                                    <TableCell>{team.gf}-{team.ga}</TableCell>
                                                    <TableCell>{parseInt(team.gf) - parseInt(team.ga)}</TableCell>
                                                    <TableCell>{team.points}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )
                    })
                    }
                </Grid>
            </>}
            <Grid container sx={{ ...centered_flex_box, padding: 5 }} spacing={2}>
                {matches?.map((match, i) => {
                    return (
                        <Grid key={i} item xs={isMobile ? 12 : 3}>
                            <MatchCard match={match} />
                        </Grid>
                    )
                })
                }
            </Grid>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    tournament: state?.records?.tournament,
    matches: state?.records?.matches,
    groupedTeams: state?.records?.groupedTeams,
    isLoading: state?.records?.isLoading
});

const mapDispatchToProps = { getTournament, connectWebSocket };

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);