import { FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, FETCH_TOURNAMENTS, FETCH_TOURNAMENTS_SUCCESS, FETCH_TOURNAMENTS_FAIL, FETCH_MATCHES, FETCH_MATCHES_SUCCESS, FETCH_MATCHES_FAIL, FOLLOW_SUCCESS, WAIT, WAIT_SUCCESS, WAIT_FAIL, LOGOUT, LOGOUT_SUCCESS, LOGIN_FAIL } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";


export const getMatches = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_MATCHES });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getMatches)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_MATCHES_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_MATCHES_FAIL,
      });
    });
};

export const getMatch = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getMatch)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTournaments = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_TOURNAMENTS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getTournaments)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_TOURNAMENTS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_TOURNAMENTS_FAIL,
      });
    });
};

export const getTournament = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getTournament)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTeams = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getTeams)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTeam = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getTeam)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getSports = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getSports)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getSport = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getSport)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getPlayers = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getPlayers)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTournamentPlayers = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getTournamentPlayers)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};


export const getOlympiads = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getOlympiads)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getOlympiad = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getOlympiad)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getAcademies = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getAcademies)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getAcademy = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getAcademy)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getCoaches = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.common.getCoaches)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const traineeReserve = (data) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token

  postRequest(data, undefined, undefined, token, endpoints.user.academy.trainee.reserve)
    .then(async (response) => {
      const {data} = response
      return dispatch({
        type: WAIT_SUCCESS,
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: WAIT_FAIL,
      });
    });
};

export const getReservations = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.user.academy.trainee.reservations)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTraineePayments = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.user.academy.trainee.payments)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getTraineeAttendance = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.user.academy.trainee.attendance)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getCoachAttendance = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.user.academy.coach.attendance)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};

export const getCoachAgenda = (data) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token

  getRequest(data, undefined, token, endpoints.user.academy.coach.agenda)
    .then(async (response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL,
      });
    });
};