import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { DownloadAPK, Home, Tournament } from "./Pages";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/Download" exact element={<DownloadAPK />} />
      <Route path="/tournament/:tournamentId" exact element={<Tournament />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
