import { combineReducers } from "redux";
import waitingReducer from "./waitingReducer";
import getReducer from "./getReducer";
import tournamentsReducer from "./tournamentsReducer";
import matchesReducer from "./matchesReducer";

export default combineReducers({
    wait: waitingReducer,
    records: getReducer,
    matches: matchesReducer,
    tournaments: tournamentsReducer,
});