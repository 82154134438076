//Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN = "LOGIN";

//Websocket
export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";
export const WEBSOCKET_SEND = "WEBSOCKET_SEND";

//Matches
export const FETCH_MATCHES_SUCCESS = "FETCH_MATCHES_SUCCESS";
export const FETCH_MATCHES_FAIL = "FETCH_MATCHES_FAIL";
export const FETCH_MATCHES = "FETCH_MATCHES";

//Tournaments
export const FETCH_TOURNAMENTS_SUCCESS = "FETCH_TOURNAMENTS_SUCCESS";
export const FETCH_TOURNAMENTS_FAIL = "FETCH_TOURNAMENTS_FAIL";
export const FETCH_TOURNAMENTS = "FETCH_TOURNAMENTS";

//Websocket
export const NEW_MATCH_UPDATE = "NEW_MATCH_UPDATE";

//Logout
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

//Change user data
export const FOLLOW_SUCCESS = "FOLLOW_SUCCESS";
export const CHANGE_SUCCESS = "CHANGE_SUCCESS";
export const CHANGE_FAIL = "CHANGE_FAIL";
export const CHANGE = "CHANGE";

//General Purpose
export const WAIT_SUCCESS = "WAIT_SUCCESS";
export const WAIT_FAIL = "WAIT_FAIL";
export const WAIT = "WAIT";

export const FETCH_RECORDS_SUCCESS = "FETCH_RECORDS_SUCCESS";
export const FETCH_RECORDS_FAIL = "FETCH_RECORDS_FAIL";
export const FETCH_RECORDS = "FETCH_RECORDS";