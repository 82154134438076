import React from "react";
import "./App.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import BackToTop from 'react-back-to-top';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BottomBar } from "./app/components";
import ScrollToTop from "./app/components/RouteToTop";

const theme = createTheme({
  palette: {
    primary: {
      main: '#180731',
      secondary: "#2a0d36"
    },
    secondary: {
      main: '#180731'
    },
    white: {
      main: "#faf9f8"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <div className="fullPage">
              <Router />
            <ScrollToTop />
            <BackToTop
              mainStyle={{
                "border-radius": 30,
                "background-color": "var(--secColor)"
              }}
              children={<KeyboardArrowUpIcon sx={{ color: "var(--appBg)" }} />}
              isPercent={false}
              offsetTop={0}
              step={50} />
          </div>
          <BottomBar />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
