import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, TableCell, TableRow, TableHead, Table, TableContainer, TableBody, Paper, Dialog, IconButton, ToggleButtonGroup, ToggleButton, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Image } from 'antd';
import Logo from "../app/components/roar-splash.png"
import { centered_flex_box } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';

export const Home = ({ }) => {

    return (
        <Container>
            <Box sx={{ ...centered_flex_box, padding: 5 }}>
                <Image src={Logo} height={isMobile ? "50vh" : "70vh"} preview={false} />
            </Box>
            <Box sx={{ ...centered_flex_box, padding: 5, flexDirection: "row" }}>
                <Box sx={{ marginX: 2 }}>
                    <a href="https://apkpure.com/p/com.roar.roaracademy" target="_blank" rel="noreferrer">
                        <Image style={{ cursor: "pointer" }} width={isMobile ? 150 : 200} preview={false} src="https://public-integra-bucket.s3.eu-west-3.amazonaws.com/apkpure.png" />
                    </a>
                </Box>
                <Box sx={{ marginX: 2 }}>
                    <a href="https://apps.apple.com/us/app/roar-sports/id6467502626" target="_blank" rel="noreferrer">
                        <Image style={{ cursor: "pointer" }} width={isMobile ? 150 : 200} preview={false} src="https://integra-apps.s3.eu-west-3.amazonaws.com/badges/256x256+Apple.png" />
                    </a>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);