import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, TableCell, TableRow, TableHead, Table, TableContainer, TableBody, Paper, Dialog, IconButton, ToggleButtonGroup, ToggleButton, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Image } from 'antd';
import Logo from "../app/components/roar-splash.png"
import { centered_flex_box, main_button } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import download from 'downloadjs';

export const DownloadAPK = ({ }) => {
    return (
        <Container>
            <Box sx={{ ...centered_flex_box, padding: 5 }}>
                <Image src={Logo} height={isMobile ? "50vh" : "70vh"} preview={false} />
            </Box>
            <Box sx={{ ...centered_flex_box, padding: 5, flexDirection: "row" }}>
                <Box sx={{ marginX: 2 }}>
                    <Button href='https://public-integra-bucket.s3.eu-west-3.amazonaws.com/ROAR.apk' sx={{...main_button}}>Download for Android</Button>
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAPK);