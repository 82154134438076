import { Card, CardContent, Grid, Typography } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { centered_flex_box } from "./Styles";
import moment from "moment/moment";
import { EmojiEvents } from "@mui/icons-material";

function MatchCard({ match }) {

  return (
    <Card sx={{ height: 120 }}>
      <CardContent sx={{ ...centered_flex_box, flexDirection: "column" }}>
        {match.tournament.type !== "League" && <Typography fontSize={13} color={match.round === "Final" ? "gold" : "grey"} textAlign="center">{match.stage === "Knockout Match" ? (match.round === "Final" ? <EmojiEvents /> : match.round) : match.stage}</Typography>}
        <Grid container>
          <Grid item xs={4}>
            <Typography fontWeight={match.ended && match.scoreA > match.scoreB ? "bold" : "none"} textAlign="start">{match.teamA.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            {match.ended && <Typography textAlign="center">{match.scoreA} - {match.scoreB}</Typography>}
            {match.started && !match.ended && <Typography sx={{ color: "green" }} textAlign="center">{match.scoreA} - {match.scoreB}</Typography>}
            {!match.started && <Typography textAlign="center">{moment(match.date).add(-2, "hours").format("hh:mm a")}</Typography>}
          </Grid>
          <Grid item xs={4}>
            <Typography fontWeight={match.ended && match.scoreA < match.scoreB ? "bold" : "none"} textAlign="end">{match.teamB.name}</Typography>
          </Grid>
        </Grid>
        {!match.started && <Typography textAlign="center" fontSize={13} color="grey">{moment(match.date).add(-2, "hours").format("ll")}</Typography>}
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MatchCard);
